// ==============================
// Logo
// =============================

.logo-wrapper {
  float: left;

  .logo {
    font-size: $logo-font-size;
    font-family: $logo-font-family;
  }
  .logo img {
    height:48px;
  }
  .logo span {
    font-size:28px;
    padding-top:5px;
  }
}

@include max-screen() {
  .logo-wrapper {
    display: none;
  }
  .logo img {
    height:48px;
  }
}
